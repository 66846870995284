'use client'

import { Player } from '@lottiefiles/react-lottie-player'
import lottiefile from './innie_boothman.json'

export default function BooniePlayer() {
  return (
    <Player
      autoplay
      controls
      loop
      src={lottiefile}
      className="h-340 lg:h-auto lg:min-w-500"
    />
  )
}
